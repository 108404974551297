import Vue from 'vue'
import Vuex from 'vuex'
// 设备模块的小仓库
import {
    deleteInvalid,
    redeletHomeSb,
    regetHomeSb,
    reqInvita,
    reqKgzt,
    reqOldPList,
    reqRtcz, reqRtdd, reqRtsm,
    reqSbglList,
    reqSbnum,
    reqSbxqList,
    reqUserQxList,
    reqUsers,
    reqaddUserPermiss,
    reqaddhomeSb,
    reqdeleteZizhanghao,
    reqgetMueu,
    reqremoveUserPermiss,
    reqzizhanghu,
    updateUser,
} from '@/api'

import {
    addnewCmmums,
    addnewHomes,
    delecommunityIds,
    deletenewHomes,
    reqCommunList,
    reqHomeList,
    reqLogin,
    reqLogon,
    reqSbzthf,
    reqSbzthp,
    reqSbzths,
    reqSbztkg,
    reqSbztmc,
    reqSbztpir,
    reqSbztsj,
    reqSbztsos,
    reqgetFPhome,
    reqgetFPzc,
    reqgetSBRZS, reqgetSBSJS,
    reqrefresh,
    reqsetFPzc,
    reqsetHomeFPzc,
    updatenewCommums,
    updatenewHomes
} from '@/api/task'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        // isShowLoading: false, // 全局 loading
        // 左侧菜单栏数据
        menuItems: [
            {
                name: 'home', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '主页', // 文本内容
            },
            {
                text: '消息管理',
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'msg',
                        text: '查看消息',
                    },
                    {
                        type: 'md-lock',
                        name: 'password',
                        text: '个人信息',
                    },
                    {
                        type: 'md-person',
                        name: 'userinfo',
                        text: '基本资料',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'md-shuffle', // icon类型
                text: '设备中心', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'deviceManagement',
                        text: '设备管理',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'equipmentControl',
                        text: '设备状态',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'ios-people', // icon类型
                text: '社区管控', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'communityPersonnel',
                        text: '社区管理',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'eventMonitoring',
                        text: '事件监控',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'md-people', // icon类型
                text: '家庭管控', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'homeMonitoring',
                        text: '家庭监控',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'dataStatostics',
                        text: '数据统计',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'ios-hammer', // icon类型
                text: '权限控制', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'userList',
                        text: '用户权限',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'menuList',
                        text: '菜单权限',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'md-ribbon', // icon类型
                text: '账户中心', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'subAccount',
                        text: '子账户管理',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'md-mail', // icon类型
                text: '信息中心', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'manageRwcording',
                        text: '用户反馈',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'emergencyBroadcasting',
                        text: '紧急播报',
                    },
                ],
            },
            {
                size: 18, // icon大小
                type: 'md-body', // icon类型
                text: '康养老人', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
                children: [
                    {
                        type: 'ios-notifications-outline',
                        name: 'besicInformation',
                        text: '基本信息',
                    },
                    {
                        type: 'ios-notifications-outline',
                        name: 'specialCare',
                        text: '数据汇总',
                    },
                ],
            },
            {
                name: 'visualization',
                size: 18, // icon大小
                type: 'ios-egg-outline', // icon类型
                text: '数据大屏', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
            },
            // {
            //     name: 'userlogs',
            //     size: 18, // icon大小
            //     type: 'ios-search', // icon类型
            //     text: '设备日志', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
            // },
        ],
        SbglList: {},
        Sbxq: {},
        Sbzt: {},
        Sbzc: {},
        Users: {},
        Invite: undefined,
        homeList: [],
        commumList: [],
        UserQxList: [],
        Sbrz: [],
        SbNum: {},
        homeSblist: [],
        zizhanghaoList: [],
        oldeList: [],
        menuList: [],
        fpzc: {},
        sbrzList: [],
        sbsjrzList: [],
        sbhomeList: [],
        token: sessionStorage.getItem('token'),
        sign: sessionStorage.getItem('sign'),
    },
    mutations: {
        updateSbrzList(state, newList) {
            state.sbrzList = newList;
        },
        clearSbrzList(state) {
            state.sbrzList = [];
        },
        setMenus(state, items) {
            state.menuItems = [...items]
        },
        // setLoading(state, isShowLoading) {
        //     state.isShowLoading = isShowLoading
        // },
        GETSbglLIST(state, SbglList) {
            state.SbglList = SbglList
        },
        GETSbglLISTS(state, SbglList) {
            state.SbglList = SbglList.SbglList
        },
        GETSbxqLIST(state, Sbxq) {
            state.Sbxq = Sbxq
        },
        GETSBZT(state, Sbzt) {
            state.Sbzt = Sbzt
        },
        GETRTZC(state, Sbzc) {
            state.Sbzc = Sbzc
        },
        GETUSER(state, Users) {
            state.Users = Users
        },
        GETINVITE(state, Invite) {
            state.Invite = Invite
        },
        GETHOMELIST(state, homeList) {
            state.homeList = homeList
        },
        GETUserQxLIST(state, UserQxList) {
            state.UserQxList = UserQxList
        },
        GETSBRZ(state, Sbrz) {
            state.Sbrz = Sbrz
        },
        GETSBNUM(state, SbNum) {
            state.SbNum = SbNum
        },
        GETCOMMUMLIST(state, commumList) {
            state.commumList = commumList
        },
        HOMESBLIST(state, homeSblist) {
            state.homeSblist = homeSblist
        },
        ZIZHANGHU(state, zizhanghaoList) {
            state.zizhanghaoList = zizhanghaoList
        },
        GETOLDE(state, oldeList) {
            state.oldeList = oldeList
        },
        GETMENU(state, menuList) {
            state.menuList = menuList
        },
        GETFPZC(state, fpzc) {
            state.fpzc = fpzc
        },
        GETSBRZS(state, sbrzList) {
            state.sbrzList = sbrzList
        },
        GETSBSJRZS(state, sbsjrzList) {
            state.sbsjrzList = sbsjrzList
        },
        GETFPHOME(state, sbhomeList) {
            state.sbhomeList = sbhomeList
        },
    },
    actions: {
        // 用户注册
        async postForm({ commit }, ruleForm) {
            console.log(ruleForm)
            let result = await reqLogon(ruleForm)
            return result.data.code
        },
        // 用户登录
        async postLogin({ commit }, loginList) {
            console.log(loginList)
            let result = await reqLogin(loginList)
            if (result.data.code == 200) {
                console.log(result.headers)
                sessionStorage.setItem('token', result.headers.token)
                sessionStorage.setItem('sign', result.headers.sign)
                return result.data
            }
            return result.data
        },
        // 设备管理（全部数据）

        async getSbglList({ commit }, pages) {

            let result = await reqSbglList(pages)

            console.log('设备状态筛选查看', result);

            commit('GETSbglLIST', result.data.data)

        },

        // 查询每一个设备的详情
        async getSbxqList({ commit }, id) {
            let result = await reqSbxqList(id)
            if (result.data.code == 200) {
                commit('GETSbxqLIST', result.data)
            }
        },

        // 设备状态
        // 设备状态的详情
        // async getSbzt({ commit }, eqt) {
        //     let result = await reqSbzt(eqt)
        //     if (result.data.code == 200) {
        //         commit('GETSBZT', result.data.data)
        //     }
        // },
        // hp传感器
        async getSbzthp({ commit }, id) {
            let result = await reqSbzthp(id)
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
        },
        // hf传感器
        async getSbzthf({ commit }, id) {

            let result = await reqSbzthf(id)
            console.log(result.data.data);
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }

            return result.data.data;
        },
        // hs传感器（呼吸睡眠）
        async getSbzths({ commit }, id) {
            let result = await reqSbzths(id)
            console.log("呼吸睡眠传感器 - 状态");
            // console.log(result);
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
            return result;
        },
        // sj传感器（水浸）
        async getSbztsj({ commit }, id) {
            let result = await reqSbztsj(id)
            console.log("水浸传感器 - 状态");
            // console.log(result);
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
            return result;
        },
        // 人体传感器
        async getSbztpir({ commit }, id) {
            let result = await reqSbztpir(id)
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
        },
        // 门磁
        async getSbztmc({ commit }, id) {
            let result = await reqSbztmc(id)
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
        },
        // sos
        async getSbztsos({ commit }, id) {
            let result = await reqSbztsos(id)
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
        },
        // 开关
        async getSbztkg({ commit }, id) {
            let result = await reqSbztkg(id)
            if (result.data.code == 200) {
                commit('GETSBZT', result.data.data)
            }
        },
        // async getNsbzt({ commit }, neweqt) {
        //     let result = await reqNsbzt(neweqt)
        //     if (result.data.code == 7500) {
        //         // console.log(result)
        //         commit('GETNSBZT', result.data.data)
        //     }
        // },
        async getKgzt({ commit }, Rtcz) {
            // console.log("AAA:"+commit+"  BBB:"+JSON.stringify(Rtcz));
            let result = await reqKgzt(Rtcz)
        },
        // 人体存在
        async getRtcz({ commit }, Rtcz) {
            let result = await reqRtcz(Rtcz)
            if (result.data.code == 200) {
                alert('设置成功！！')
            }
        },
        // 人体跌倒
        async getRtdd({ commit }, Rtdd) {
            let result = await reqRtdd(Rtdd)
            if (result.data.code == 200) {
                alert('设置成功！！')
            }
        },
        // 人体睡眠
        async getRtsm({ commit }, Rtsm) {
            let result = await reqRtsm(Rtsm)
            if (result.data.code == 200) {
                alert('设置成功！！')
            }
        },
        // 用户信息
        async getUsers({ commit }) {
            let result = await reqUsers()
            if (result.data.code == 200) {
                commit('GETUSER', result.data.data)
            }
            return result.data.code
        },
        // 邀请码
        async getInvitation({ commit }, value) {
            let result = await reqInvita(value)
            if (result.data.code == 200) {
                commit('GETINVITE', result.data.data)
            }
        },
        async updatUser({ commit }, User) {
            let result = await updateUser(User)
            if (result.data.code == 200) {
                return result.data.code
            }
        },
        // 获取全部家庭
        async getHomeList({ commit }, page) {

            let result = await reqHomeList(page)

            // console.log('家庭数据', result.data.data)
            if (result.data.code == 200) {
                commit('GETHOMELIST', result.data.data)
            }
        },
        // 增加家庭
        async addHomes({ commit }, homes) {
            let result = await addnewHomes(homes)
            //
            console.log("========[家庭添加结果]=====");
            console.log(result);
            return result.data.code
        },
        // 删除家庭
        async deleHomes({ commit }, homeId) {
            let result = await deletenewHomes(homeId)
            console.log("==============家庭删除结果=============");
            console.log(result);
            return result.data.code
        },

        async updateHomes({ commit }, homes) {
            let result = await updatenewHomes(homes)
            console.log("===========家庭修改结果==========");
            console.log(result);
            return result.data.code
        },
        // 获取社区
        async getCommumList({ commit }, page) {
            let result = await reqCommunList(page)
            console.log(result)
            if (result.data.code == 200) {
                commit('GETCOMMUMLIST', result.data.data)
            }
        },
        // 增加社区
        async addCommum({ commit }, Commum) {
            let result = await addnewCmmums(Commum)
            return result.data.code
        },
        // 删除社区
        async delecommunityId({ commit }, homeId) {
            let result = await delecommunityIds(homeId)

            console.log("----社区删除结果----");
            console.log(result);

            return result.data.code
        },
        // 修改社区
        async updateCommum({ commit }, commum) {
            let result = await updatenewCommums(commum)
            return result.data.code
        },
        // 查询用户子权限全部
        async getUserQxList({ commit }, pages) {
            let result = await reqUserQxList(pages)
            //
            console.log('查询当前用户子账号: ', result)
            commit('GETUserQxLIST', result.data.data)
        },
        // 增加子用户权限
        async addUserPermiss({ commit }, userPermiss) {
            let result = await reqaddUserPermiss(userPermiss)
            return result
        },
        // 删除子用户权限
        async removeUserpermiss({ commit }, id) {
            let result = await reqremoveUserPermiss(id)
            return result
        },
        async refresh({ commit }, id) {
            let result = await reqrefresh(id)
            return result
        },
        // 设备数量
        async getSbNum({ commit }) {
            let result = await reqSbnum()
            console.log(result)
            if (result.data.code == 200) {
                commit('GETSBNUM', result.data.data)
            }
        },
        // 获取家庭设备
        async getHomeSb({ commit }, page) {
            let result = await regetHomeSb(page)
            if (result.data.code == 200) {
                commit('HOMESBLIST', result.data.data)
            }
        },
        // 家庭设备绑定
        async addhomeSb({ commit }, addhome) {
            let result = await reqaddhomeSb(addhome)
            return result.data.code
        },
        // 解除绑定
        async deletHomeSb({ commit }, bindId) {
            let result = await redeletHomeSb(bindId)
            return result.data.code
        },
        // 获取子账号
        async getzizhanghu({ commit }, page) {
            let result = await reqzizhanghu(page)
            if (result.data.code == 200) {
                commit('ZIZHANGHU', result.data.data)
            }
        },
        // 移除子账号
        async deleteZizhanghao({ commit }, uuid) {
            let result = await reqdeleteZizhanghao(uuid)
            return result.data
        },
        // 查询老人
        async getOldPList({ commit }, page) {
            let result = await reqOldPList(page)
            console.log("result: ===========================", result);
            if (result.data.code == 200) {
                commit('GETOLDE', result.data.data)
            }
        },
        // 获取菜单
        async getMueu({ commit }) {
            let result = await reqgetMueu()

            console.log("menuList", result);

            if (result.data.code == 200) {
                commit('GETMENU', result.data.data)
            }

            return result.data.data;
        },
        // 分配资产
        async getFPzc({ commit }) {
            let result = await reqgetFPzc()
            if (result.data.code == 200) {
                commit('GETFPZC', result.data.data)
            }
        },
        // 设置权限(分配 社区 资产)
        async setFPzc({ commit }, fpzcs) {
            let result = await reqsetFPzc(fpzcs)
            console.log("社区资产分配结果...", result);
            return result.data
        },
        // 状态日志
        async getSBRZS({ commit }, deviceId) {
            let result = await reqgetSBRZS(deviceId)
            if (result.data.code == 200) {
                // console.log("GETSBRZS:"+JSON.stringify(result.data));
                // commit('GETSBRZS', result.data.data)
                commit('GETSBRZS', result.data)
            }
        },
        // 事件日志
        async getSBSJS({ commit }, deviceId) {
            let result = await reqgetSBSJS(deviceId)
            if (result.data.code == 200) {
                commit('GETSBSJRZS', result.data.data)
            }
        },
        // 获取家庭 信息
        async getAllocationHome({ commit }) {
            let result = await reqgetFPhome()
            if (result.data.code == 200) {
                commit('GETFPHOME', result.data.data)
            }
        },
        // 设置家庭分配
        async sethomeFp({ commit }, fphomes) {
            let result = await reqsetHomeFPzc(fphomes)
            console.log("家庭资产分配结果...", result);
            return result.data
        },
    },
    getters: {
        getSbztNu(state) {
            // console.log("上:"+JSON.stringify(state.Sbzt));
            return deleteInvalid(state.Sbzt)
        },
        getSbglList(state) {
            return state.SbglList.records
        },
        pageInfo: state => ({
            total: state.SbglList.total,
        }),
    },
})

export default store