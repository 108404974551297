// 导入内部 路由
import { asyncRoutes } from '@/router'

// 将菜单信息转成对应的路由信息 动态添加
export default function createRoutes(data) {
    // 最终路由结果
    const result = []
    // 子路由
    const children = []

    // 最终路由 追加 / 跳转到Index.vue
    result.push({
        path: '/',
        component: () => import('../components/Index.vue'),
        // component: () => import('../components/visualization'),
        children,
    })

    //  遍历vuex中的菜单，逐个追加到  result最终路由中
    data.forEach(item => {
        // 自定义工具方法
        generateRoutes(children, item)
    })

    // 最后添加404页面 否则会在登陆成功后跳到404页面
    result.push(
        { path: '*', redirect: '/404' },
    )
    console.log(result)
    return result
}

// 生成路由方法
function generateRoutes(children, item) {
    // 有name属性的
    if (item.name) {
        // 拿到内部路由的 name 判断 是否存在
        if (asyncRoutes[item.name]) {
            // 如果存在
            children.push(asyncRoutes[item.name])
        }
    } else if (item.children) {
        item.children.forEach(e => {
            generateRoutes(children, e)
        })
    }
}


// result [ / , 404 ]
// children [ home , msg, password,  userinfo,
//  deviceManagement , equipmentControl
// ]


/**
 *    result : [
 *      { /  ....
 *              children: [...]
 *       }
 *
 *         {*404 .....}
 * ]
 *
 *
 */
