/*
 * @Author: shop-money 2096047397@qq.com
 * @Date: 2023-03-22 16:49:50
 * @LastEditors: shop-money 2096047397@qq.com
 * @LastEditTime: 2023-05-25 11:41:22
 * @FilePath: \gantchsmart\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import md5 from 'js-md5'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import Vue from 'vue'
import App from './App'
import './permission'
import router from './router'
import store from './store'
// import socketApi from '@/utils/socket.js'


// Vue.prototype.socketApi = socketApi
Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(ElementUI)

// Vue组件定义
export default {
    mounted() {
        yourPromiseObject.then(this.handleAsyncResult);
    },
    methods: {
        handleAsyncResult(result) {
            // 处理异步操作的结果
            console.log(result);
        }
    }
}

// ----全局时间格式化
//对名称进行定义,提供一个function函数   originVal  为形参
Vue.filter('dateFormat', function (originVal) {
    const dt = new Date(originVal)
    //年的时间
    const y = dt.getFullYear()
    //月的时间  .padStart 不足两位自动补0  2位长度
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    //日的时间
    const d = (dt.getDate() + '').padStart(2, '0')
    //小时
    const hh = (dt.getHours() + '').padStart(2, '0')
    //分钟
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    //秒数
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    //将它们拼接成完整的字符串
    //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})


Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.prototype.$md5 = md5


// 语音提醒
// import { voicePrompt } from '@/api'
Vue.prototype.$voice = new Audio('https://cdn.tosound.com:3321/preview?file=freesound%2F0%2F118%2F220809.mp3&token=ZnJlZXNvdW5kJTJGMCUyRjExOCUyRjIyMDgwOS5tcDM=&sound=audio.mp3');
// Vue.prototype.$voice = new Audio('https://vod.ruotongmusic.com/sv/1822f812-179cd1c6baa/1822f812-179cd1c6baa.wav');


new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
