
// View UI 引入
import { LoadingBar } from 'view-design'

// 从路由依赖库中  引入 路由器
import router from './router'

// vuex 仓库 引入
import store from './store'

// 创建 动态路由 方法
import createRoutes from '@/utils/createRoutes'

// 获取文档标题方法、重置用户 数据方法
import { getDocumentTitle, resetTokenAndClearUser } from './utils'

// 是否有菜单数据
let hasMenus = false

// 路由器前置守卫
router.beforeEach(async (to, from, next) => {
    // 文档标题设置
    document.title = getDocumentTitle(to.meta.title)

    // 加载圈 开始
    LoadingBar.start()

    // 判断 token 校验
    if (sessionStorage.getItem('token')) {
        // 如果为 login 路由则 跳转 为
        if (to.path === '/login') {
            next({ path: '/' })
        } else if (hasMenus) { // 判断 是否 存在菜单数据
            // 存在 则直接 放行
            next()
        } else {
            try {
                // 这里可以用 await 配合请求后台数据来生成路由
                // const data = await axios.get('xxx')
                // const routes = createRoutes(data)

                // 拿到vuex中写好的menu菜单数据
                const routes = createRoutes(store.state.menuItems)
                // 动态添加路由
                router.addRoutes(routes)
                // 存在菜单（直接放行）
                hasMenus = true
                // 然后跳转到 首页
                next({ path: to.path || '/' })
            } catch (error) {
                // 报错则，清空数据，跳转到登录页面
                resetTokenAndClearUser()
                next(`/login?redirect=${to.path}`)
            }
        }
    } else {
        // token 不存在则，表示没有菜单
        hasMenus = false
        // 注册 和 登录 放行
        if (to.path === '/login' || to.path === '/logon') {
            next()
        } else {
            // 否则 跳转登录页面
            next(`/login?redirect=${to.path}`)
        }
    }
})

// 路由器 后置 守卫
router.afterEach(() => {
    // 路由完成之后， 加载效果消失
    LoadingBar.finish()
})
