/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-22 16:49:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-04-14 09:52:39
 * @FilePath: \gantchsmart\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-22 16:49:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-04-13 09:27:06
 * @FilePath: \gantchsmart\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/logon',
        meta: { title: '注册' },
        component: () => import('../components/Logon.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    {
        path: '/visualization',
        name: 'visualization',
        meta: { title: '可视化大屏' },
        component: () => import('../components/visualization'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '主页' },
        component: () => import('../views/home'),
    },
    password: {
        path: 'password',
        name: 'password',
        meta: { title: '修改密码' },
        component: () => import('../views/Password.vue'),
    },
    msg: {
        path: 'msg',
        name: 'msg',
        meta: { title: '通知消息' },
        component: () => import('../views/Msg.vue'),
    },
    userinfo: {
        path: 'userinfo',
        name: 'userinfo',
        meta: { title: '用户信息' },
        component: () => import('../views/UserInfo.vue'),
    },
    // 设备智联
    // 设备管理
    deviceManagement: {
        path: 'deviceManagement',
        name: 'deviceManagement',
        meta: { title: '设备管理' },
        component: () => import('../views/equipmentZhilian/deviceManagement.vue'),
    },
    // 设备控制
    equipmentControl: {
        path: 'equipmentControl',
        name: 'equipmentControl',
        meta: { title: '设备状态' },
        component: () => import('../views/equipmentZhilian/equipmentControl.vue'),
    },
    // 社区控制

    // 社区人员
    communityPersonnel: {
        path: 'communityPersonnel',
        name: 'communityPersonnel',
        meta: { title: '社区管控' },
        component: () => import('../views/communityControl/communityPersonnel.vue'),
    },
    // 事件监控
    eventMonitoring: {
        path: 'eventMonitoring',
        name: 'eventMonitoring',
        meta: { title: '数据统计' },
        component: () => import('../views/communityControl/eventMonitoring.vue'),
    },
    // 家居控制
    // 场景控制
    homeMonitoring: {
        path: 'homeMonitoring',
        name: 'homeMonitoring',
        meta: { title: '家庭监控' },
        component: () => import('../views/homeControl/homeMonitoring.vue'),
    },
    dataStatostics: {
        path: 'dataStatostics',
        name: 'dataStatostics',
        meta: { title: '数据统计' },
        component: () => import('../views/homeControl/dataStatistics.vue'),
    },
    // 权限管理
    userList: {
        path: 'userList',
        name: 'userList',
        meta: { title: '用户管理' },
        component: () => import('../views/permissionControl/userList.vue'),
    },
    menuList: {
        path: 'menuList',
        name: 'menuList',
        meta: { title: '菜单管理' },
        component: () => import('../views/permissionControl/menuList.vue'),
    },
    subAccount: {
        path: 'subAccount',
        name: 'subAccount',
        meta: { title: '子账户管理' },
        component: () => import('../views/accountCenter/subAccount.vue'),
    },

    manageRwcording: {
        path: 'manageRwcording',
        name: 'manageRwcording',
        meta: { title: '消息记录' },
        component: () => import('../views/messageCenter/messageRecording.vue'),
    },
    emergencyBroadcasting: {
        path: 'emergencyBroadcasting',
        name: 'emergencyBroadcasting',
        meta: { title: '紧急播报' },
        component: () => import('../views/messageCenter/emergencyBroadcasting.vue'),
    },
    besicInformation: {
        path: 'besicInformation',
        name: 'besicInformation',
        meta: { title: '基本信息' },
        component: () => import('../views/healthyElderly/basicInformation.vue'),
    },
    specialCare: {
        path: 'specialCare',
        name: 'specialCare',
        meta: { title: '特殊照顾' },
        component: () => import('../views/healthyElderly/specialCare.vue'),
    },
    // userlogs: {
    //     path: 'userlogs',
    //     name: 'userlogs',
    //     meta: { title: '个人日志' },
    //     component: () => import('../views/userlogs/userlogs.vue'),
    // },
}

const createRouter = () => new Router({
    mode: 'hash',
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
