/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-15 17:14:25
 * @LastEditors: shop-money 2096047397@qq.com
 * @LastEditTime: 2023-05-16 15:51:41
 * @FilePath: \gantchsmart\src\utils\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { Message } from 'view-design'
import router from '@/router'
import { resetTokenAndClearUser } from '@/utils'


const instance = axios.create({
    baseURL: '/task',
    timeout: 60000,
})

// 请求拦截器
instance.interceptors.request.use(config => {
    if (sessionStorage.getItem('token') != null && sessionStorage.getItem('sign') != null) {
        config.headers.token = sessionStorage.getItem('token')
        config.headers.sign = sessionStorage.getItem('sign')
    }
    return config
}, (error) => Promise.reject(error))

// 响应拦截器
instance.interceptors.response.use(response => {
    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改
    // 错误处理
    if (res.code != 0 && res.msg) {
        Message.error({
            content: res.msg,
        })
        // token 无效，清空路由，退出登录
        if (res.code == 2) {
            resetTokenAndClearUser()
            router.push('login')
        }
        return Promise.reject()
    }

    // 如果接口正常，直接返回数据
    return res
}, (error) => {
    if (error.name == 'Error') {
        Message.error({
            content: error.msg,
        })
    } else {
        Message.error({
            content: error.response.data.data || error.message,
        })
    }
    return Promise.reject(error)
})

export default instance
