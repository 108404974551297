import instance from '@/utils/api';



// 注册
export const reqLogon = (data) => instance({
    url: '/user/test/logon',
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
    },
    data:
    {
        accountDescription: data.accountDescription,
        accountName: data.accountName,
        accountPassword: data.accountPassword,
        accountPasswordAgain: data.accountPasswordAgain,
        identity: data.identity,
        invitationCode: data.invitationCode,
        phoneNumber: data.phoneNumber,
    },
})
// 登录
export const reqLogin = (data) => instance({
    url: '/user/test/login',
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
    },
    data:
    {
        accountName: data.accountName,
        accountPassword: data.accountPassword,
        phoneNumber: data.phoneNumber,
    },
})
// 家庭控制
export const reqHomeList = (data) =>
    instance({
        url: '/home/page',
        method: 'post',
        data: {
            pageNum: data.index,
            pageSize: data.size,
            // pageNum: 1,
            // pageSize: 20,
        },
    })
// 获取家庭添加中的社区
export const reqCommun = () => instance({
    url: '/community/child/list',
    method: 'post',
})
// 增加家庭
export const addnewHomes = (data) => instance({
    url: '/home/create',
    method: 'post',
    data: {
        homeAddress: data.homeAddress,
        communityId: data.arr.communityId,
        parentAssetId: data.arr.tuyaAssetId,
        managerUser: data.managerUser,
        homeDescription: data.homeDescription,
        homeName: data.homeName,
    },
})
// 删除家庭
export const deletenewHomes = (data) => instance({
    url: '/home/remove',
    method: 'get',
    params: {
        homeId: data.homeId,
        assetId: data.assertId
    },
})
// 修改家庭
export const updatenewHomes = (data) => instance({
    url: '/home/update',
    method: 'post',
    data: {
        homeAddress: data.homeAddress,
        communityId: data.arr.communityId,
        parentAssetId: data.arr.tuyaAssetId,
        managerUser: data.managerUser,
        homeDescription: data.homeDescription,
        homeName: data.homeName,
        assertId: data.tuyaAssetId,
        homeId: data.homeId
    },
})
// 获取社区
export const reqCommunList = (page) => instance({
    url: '/community/page',
    method: 'post',
    data: {
        pageNum: page.pageNum,
        pageSize: page.pageSize,
        communityName: page.communityName
    },
})
// 增加社区
export const addnewCmmums = (data) => instance({
    url: '/community/create',
    method: 'post',
    data: {
        communityAddress: data.communityAddress,
        communityDescription: data.communityDescription,
        communityName: data.communityName,
    },
})
// 删除社区
export const delecommunityIds = (data) => instance({
    url: '/community/del',
    method: 'get',
    params: {
        communityId: data.communityId,
        assetId: data.assertId
    },
})
// 修改社区
export const updatenewCommums = (data) => instance({
    url: '/community/update',
    method: 'post',
    data: {
        communityAddress: data.communityAddress,
        communityDescription: data.communityDescription,
        communityName: data.communityName,
        managerUser: data.managerUser,
        communityId: data.communityId,
    },
})
export const reqrefresh = () => instance({
    url: '/device/catch/refresh',
    method: 'post',
})
// hp
export const reqSbzthp = (id) => instance({
    url: '/category/status/hps/hp',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// hs （呼吸睡眠）
export const reqSbzths = (id) => instance({
    url: '/category/status/hps/hs',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// sj 水浸
export const reqSbztsj = (id) => instance({
    url: '/category/status/hps/sj',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// hf
export const reqSbzthf = (id) => instance({
    url: '/category/status/hps/hf',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// pir
export const reqSbztpir = (id) => instance({
    url: '/category/status/pir',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// mc
export const reqSbztmc = (id) => instance({
    url: '/category/status/mc',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// sos
export const reqSbztsos = (id) => instance({
    url: '/category/status/sos',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// kg
export const reqSbztkg = (id) => instance({
    url: '/category/status/kg',
    method: 'get',
    params: {
        deviceId: id,
    },
})
// 分配资产查询
export const reqgetFPzc = () => instance({
    url: '/community/child/list/allocation',
    method: 'post',
})
// 设置资产 (分配 社区 资产)
export const reqsetFPzc = (data) => instance({
    url: '/auth/allocation/community',
    method: 'get',
    params: {
        communityStringId: data.community.communityId,
        communityTuyaAssetId: data.community.tuyaAssetId,
        userUuid: data.uuid,
    },
})
// 获取设备状态日志
export const reqgetSBRZS = (deviceId) => instance({
    url: '/log/status',
    method: 'get',
    params: {
        deviceId,
    },
})
// 获取设备事件日志
export const reqgetSBSJS = (deviceId) => instance({
    url: '/log/event',
    method: 'get',
    params: {
        deviceId,
    },
})
// 获取家庭分配

export const reqgetFPhome = () => instance({
    url: '/home/child/list',
    method: 'post',
})

// 设置家庭分配
export const reqsetHomeFPzc = (data) => instance({
    url: '/auth/allocation/home',
    method: 'get',
    params: {
        homeStringId: data.homes.homeId,
        homeTuyaAssetId: data.homes.tuyaAssetId,
        userUuid: data.uuid,
    },
})


// 获取设备事件日志
// eslint-disable-next-line max-len
export const getMyEventLogs = (data) => instance({
    url: '/log/event/community',
    method: 'get',
    params: {
        pageSize: data.pageSize || 20,
        pageNum: data.pageNum || 1,
        deviceId: data.deviceId || '',
    },

})

//getBaoJinEventLogs 获取紧急播报信息
export const getBaoJinEventLogs = (data) => instance({
    url: '/log/event/baojinEvent',
    method: 'get',
    params: {
        pageSize: data.pageSize || 20,
        pageNum: data.pageNum || 1,
        deviceId: data.deviceId || '',
    },

})

// reqgetSPState /摇头机 状态
export const reqgetSPState = (deviceId) => instance({
    url: '/category/status/sp',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetLOCKState /指纹锁 状态
export const reqgetLOCKState = (deviceId) => instance({
    url: '/category/status/lock',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetLOCKState /窗帘开关 状态
export const reqgetCLKGState = (deviceId) => instance({
    url: '/category/status/clkg',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetDJState /wifi智能灯泡 状态
export const reqgetDJState = (deviceId) => instance({
    url: '/category/status/dj',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetBJYYState /背景音乐 状态
export const reqgetBJYYState = (deviceId) => instance({
    url: '/category/status/bjyy',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetDGNZKState /智能中控屏 状态
export const reqgetDGNZKState = (deviceId) => instance({
    url: '/category/status/dgnzk',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetKTState /空调 状态
export const reqgetKTState = (deviceId) => instance({
    url: '/category/status/kt',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetWG2State /中央空调集控网关 状态
export const reqgetWG2State = (deviceId) => instance({
    url: '/category/status/wg2',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetCLState /窗帘状态
export const reqgetCLState = (deviceId) => instance({
    url: '/category/status/cl',
    method: 'get',
    params: {
        deviceId,
    },
})
// reqgetWSDCGState /温湿度传感器 状态
export const reqgetWSDCGState = (deviceId) => instance({
    url: '/category/status/wsdcg',
    method: 'get',
    params: {
        deviceId,
    },
})

// 最新脉冲 /task/log/checkDown
// reqgetSPState /摇头机 状态
export const reqgetNewSP = (deviceId) => instance({
    url: '/log/checkDown',
    method: 'get'
})


// 脉冲报警数据/task/log/callLogs
export const reqgetNewSPCall = (page) => instance({
    url: '/log/callLogs',
    method: 'post',
    data: {
        pageNum: page.pageNum || 1,
        pageSize: page.pageSize || 20,
        deviceId: page.deviceId || ''
    }
})

// 授权号码addPhone
export const userAddPhone = (phone) => instance({
    url: '/user/addPhone',
    method: 'get',
    params: {
        phone: phone || ''
    }
})

// 获取 计费 信息 接口/task/jf/info
export const getJfInfo = (phone) => instance({
    url: '/jf/info',
    method: 'get'
})

// 充值 费用 信息 接口/task/jf/putJF

// czaccount:{
//     msgNum: 0,
//     callNum: 0,
//     userid: ''
// },
export const putCZInfo = (data) => instance({
    url: '/jf/putJF',
    method: 'post',
    data
})


