import request from '@/utils/request'




// 获取设备管理
// eslint-disable-next-line max-len
export const reqSbglList = (data) => request({
    url: '/device/msg/catch/all',
    method: 'post',
    data: {
        homeName: data.homeName || '',
        category: data.category || '',
        name: data.name || '',
        pageSize: data.pageSize || 200,
        // pageSize: data.size || 200,
        pageNum: data.pageNum || 1,
    },
})

// 用于扩充功能的设备详情
export const reqSbxqList = (id) => request({
    url: '/device/msg/catch/all',
    method: 'post',
    data: {
        deviceId: id,
    },
})

// 删除胸牌设备： /api/device/msg/del/id
export const delDevice = (id) => request({
    url: '/device/msg/del/' + id,
    method: 'delete'
})


// 设备状态
// export const reqSbzt = (eqt) => request({
//     url: '/device/msg/catch/status',
//     method: 'get',
//     params: {
//         deviceId: eqt.id,
//         category: eqt.newcategory,
//     },
// })

// 开关控制
export const reqKgzt = (params) => request({
    url: '/device/ctrl/switch/general',
    method: 'get',
    params: {
        deviceId: params.id,
        status1: params.switchStatus1,
        status2: params.switchStatus2,
        status3: params.switchStatus3,
        status4: params.switchStatus4,
    },
})

//reqsetSPState 设置 摇头机 SP 状态
export const reqsetSPState = (data) => request({
    url: '/device/ctrl/sensor/sp',
    method: 'post',
    data
})
//reqsetLOCKState 设置 指纹锁 LOCK 状态
export const reqsetLOCKState = (data) => request({
    url: '/device/ctrl/sensor/lock',
    method: 'post',
    data
})

//reqsetCLKGState 设置 窗帘开关 CLKG 状态
export const reqsetCLKGState = (data) => request({
    url: '/device/ctrl/sensor/clkg',
    method: 'post',
    data
})
//reqsetDJState 设置 智能灯泡 dj 状态
export const reqsetDJState = (data) => request({
    url: '/device/ctrl/sensor/dj',
    method: 'post',
    data
})
//reqsetDJState 设置 背景音乐 bjyy 状态
export const reqsetBJYYState = (data) => request({
    url: '/device/ctrl/sensor/bjyy',
    method: 'post',
    data
})
//reqsetDJState 设置 中央空调集控网关 wg2 状态
export const reqsetWG2State = (data) => request({
    url: '/device/ctrl/sensor/wg2',
    method: 'post',
    data
})
//reqsetDJState 设置 空调 kt 状态
export const reqsetKTState = (data) => request({
    url: '/device/ctrl/sensor/kt',
    method: 'post',
    data
})
//reqsetDJState 设置 智能中控屏 dgnzk 状态
export const reqsetDGNZKState = (data) => request({
    url: '/device/ctrl/sensor/dgnzk',
    method: 'post',
    data
})
//reqsetclState 设置 窗帘电机 cl 状态
export const reqsetCLState = (data) => request({
    url: '/device/ctrl/sensor/cl',
    method: 'post',
    data
})
//reqsetWSDCGState 设置 温湿度传感器 wsdcg 状态
export const reqsetWSDCGState = (data) => request({
    url: '/device/ctrl/sensor/wsdcg',
    method: 'post',
    data
})

// 人体存在控制
export const reqRtcz = (params) => request({
    url: '/device/ctrl/sensor/hp',
    method: 'post',
    data: {
        deviceId: params.deviceId,
        sensitivity: params.sensitivity,
        councheckStartSwitchtdown: params.checkStartSwitch,
        scene: params.scene,
        nobodyTimeSet: params.nobodyTimeSet,
    },
})
// 人体跌倒
// 李哥源代码
// export const reqRtdd = (data) => request({
//     url: '/category/status/hps/hf',
//     method: 'post',
//     data: {
//         deviceId: data.deviceId,
//         sensitivity: data.sensitivity,
//         switch1: data.switch,
//         scene: data.scene,
//         distanceDetection: data.distanceDetection,
//         stayAlarmMode: data.stayAlarmMode,
//         alarmSwitch: data.alarmSwitch,
//         securitySwitch: data.securitySwitch,
//         angleVertical: data.angleVertical,
//         tumbleAlarmTime: data.tumbleAlarmTime,
//         staticTime: data.staticTime,
//     },
// })
export const reqRtdd = (data) => request({
    url: '/device/ctrl/sensor/hf',
    method: 'post',
    data: {
        deviceId: data.deviceId,
        sensitivity: data.sensitivity,
        switch1: data.switch,
        scene: data.scene,
        distanceDetection: data.distanceDetection,
        stayAlarmMode: data.stayAlarmMode,
        alarmSwitch: data.alarmSwitch,
        securitySwitch: data.securitySwitch,
        angleVertical: data.angleVertical,
        tumbleAlarmTime: data.tumbleAlarmTime,
        staticTime: data.staticTime,
    },
})
// 人体睡眠
export const reqRtsm = (data) => request({
    url: '/device/ctrl/sensor/hs',
    method: 'post',
    data: {
        deviceId: data.deviceId,
        breathSwitch: data.breathSwitch,
        heartbeatSwitch: data.heartbeatSwitch,
        modeSwitch: data.modeSwitch,
        sleepSwitch: data.sleepSwitch,
    },
})
// 获取用户信息
export const reqUsers = () => request({
    url: '/self/msg',
    method: 'get',
})
// 获取邀请码
export const reqInvita = (data) => request({
    url: '/self/msg/invite',
    method: 'get',
    params: {
        userFlag: data,
    },
})
// 用户修改
export const updateUser = (data) => request({
    url: '/self/msg/update',
    method: 'post',
    data: {
        accountDescription: data.accountDescription,
        accountName: data.accountName,
        accountPassword: data.accountPassword,
        accountPasswordAgain: data.accountPasswordAgain,
        identity: data.identity,
        invitationCode: data.invitationCode,
        phoneNumber: data.phoneNumber,
        realName: data.realName,
    },
})
// 获取用户子权限
export const reqUserQxList = (pages) => request({
    url: '/auth/list',
    method: 'post',
    data: {
        accountName: pages.accountName,
        pageSize: pages.pageSize,
        pageNum: pages.pageNum,
    },
})
// 增加用户子权限
export const reqaddUserPermiss = (userPermiss) => request({
    url: '/auth/add',
    method: 'get',
    params: {
        accountNameUser: userPermiss.accountName,
        apiDescription: userPermiss.apiDescription,
        apiValue: userPermiss.apiValue,
    },
})
export const reqremoveUserPermiss = (id) => request({
    url: '/auth/delete',
    method: 'delete',
    params: {
        id,
    },
})
export const reqSbnum = () => request({
    url: '/data/statistics',
    method: 'get',
})
// 获取家庭设备
export const regetHomeSb = (page) => request({
    url: '/bind/dh/page',
    method: 'get',
    params: {
        homeId: page.id,
        pageNum: page.pageNum,
        pageSize: page.pageSize,
    },
})
// 家庭设备绑定
export const reqaddhomeSb = (data) => request({
    url: '/bind/dh/create',
    method: 'get',
    params: {
        dmId: data.dmId,
        homeId: data.homeId,
    },
})
// 解除绑定
export const redeletHomeSb = (bindId) => request({
    url: '/bind/dh/remove',
    method: 'get',
    params: {
        bindId,
    },
})
// 获取子账号
export const reqzizhanghu = (page) => request({
    url: '/user/child/child/page',
    method: 'get',
    params: {
        pageNum: page.pageNum || '200',
        pageSize: page.pageSize,
    },
})
// 删除子账号
export const reqdeleteZizhanghao = (uuid) => request({
    url: '/user/child/child/remove',
    method: 'get',
    params: {
        uuid,
    },
})
// 获取老人
export const reqOldPList = (page) => request({
    url: '/elder/page',
    method: 'post',
    data: {
        pageNum: page.pageNum,
        pageSize: page.pageSize,
    },
})

// 获取菜单权限
export const reqgetMueu = () => request({
    url: '/auth/menu/all',
    method: 'get',
})

// 反馈数据
export const reqgetRecall = (page) => request({
    url: '/recall/msg/call/page',
    method: 'post',
    data: {
        pageSize: page.pageSize || 100,
        pageNum: page.pageNum || 1,
        accountName: page.accountName || ''
    }
})

//新增 反馈 reqaddRecall
export const reqaddRecall = (content) => request({
    url: '/recall/msg/call',
    method: 'get',
    params: {
        recall: content
    }
})
// 更新 反馈 的 方法 requpdateRecall
export const requpdateRecall = (record) => request({
    url: '/recall/msg/recall',
    method: 'get',
    params: {
        call: record.call,
        id: record.id
    }
})

// reqdeleteRecall 删除
export const reqdeleteRecall = (id) => request({
    url: '/recall/msg/call/del',
    method: 'delete',
    params: {
        id
    }
})

// 播报
// export function voicePrompt (text) {
//     new Audio('https://vod.ruotongmusic.com/sv/1822f812-179cd1c6baa/1822f812-179cd1c6baa.wav').play();
// }


// 去空方法
export const deleteInvalid = (obj) => {
    Object.keys(obj).forEach(item => {
        if (!obj[item] && obj[item] != 0) {
            delete obj[item]
        }
    })
    return obj
}


